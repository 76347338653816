import React from "react";
import { Chart } from "react-google-charts";

const gv1 = 5785 / 12912 * 100;
const gv2 = 6056 / 12912 * 100;
const gv3 = 100 - gv1 - gv2;
export const data = [
  ["Groups", "Group Incomes Percent"],
  ["Group1", gv1],
  ["Group2", gv2],
  ["Group3", gv3],
];

export const options = {
  title: "October - December competition",
  chartArea: { width: "70%" },
  is3D: true,
};

export const data2 = [
  ["Group", "Football", "Volleyball", "Ping-pong", "Other"],
  ["Group 1", 1, 0, 0, 0],
  ["Group 2", 2, 0, 0, 0],
  ["Group 3", 4, 0, 0, 0],
];

export const options2 = {
  chart: {
    title: "Groups Performance",
    subtitle: "Football, Volleyball, Ping-pong, Other",
  },
  minValue: 0,
  animation: {
    startup: true,
    easing: "linear",
    duration: 1500,
  },
};

export const data3 = [
  ["Date", "Group 1", "Group 2", "Group 3", "Type"],
  ["10/2", 1, 2, 4, 'Football'],
  ["10/9", 0, 0, 0, ''],
  ["10/16", 0, 0, 0, ''],
  ["10/23", 0, 0, 0, ''],
  ["10/30", 0, 0, 0, ''],
  ["11/6", 0, 0, 0, ''],
  ["11/13", 0, 0, 0, ''],
  ["11/20", 0, 0, 0, ''],
  ["11/27", 0, 0, 0, ''],
  ["12/4", 0, 0, 0, ''],
  ["12/11", 0, 0, 0, ''],
  ["12/18", 0, 0, 0, ''],
  ["12/25", 0, 0, 0, ''],
  ["Total", 0, 0, 0, ''],
];

export const options3 = {
  allowHtml: true,
  showRowNumber: true,
};

export const formatters = [
  {
    type: "ColorFormat",
    column: 1,
    options: {
      width: 120,
    },
    ranges: [],
  },
];

export const data4 = [
  ["Scorer", "Score"],
  ["Pak Su Gwang", 2],
  ["Ri Ryong Un", 2],
  ["Kong Un Bom", 1],
  ["Pak Sin Hyok", 1],
  ["Pak Mong Hyon", 1],
];

export const options4 = {
  allowHtml: true,
  showRowNumber: true,
};

export const formatters4 = [
  {
    type: "ColorFormat",
    column: 1,
    options: {
      width: 120,
    },
    ranges: [],
  },
];


function App() {
  return (
    <div className="App" style={{ padding: 20 }}>
      <div>
        <div style={{ width: '50%', float: 'left' }}>
          <Chart
            chartType="PieChart"
            width="100%"
            data={data}
            height={window.innerHeight - 450}
            options={options}
          />
        </div>
        <div style={{ width: '50%', float: 'left' }}>
          <Chart
            chartType="Bar"
            width="100%"
            height={window.innerHeight - 450}
            data={data2}
            options={options2}
          />
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Chart
          chartType="Table"
          width="100%"
          height="400px"
          style={{ textAlign: 'center' }}
          data={data3}
          options={options3}
          formatters={formatters}
        />
        <Chart
          chartType="Table"
          width="100%"
          height="400px"
          // style={{ textAlign: 'center' }}
          data={data4}
          options={options4}
          formatters={formatters4}
        />
      </div>
    </div>
  );
}

export default App;
